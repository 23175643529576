import React from "react";
import { User } from "database";
import { cva, VariantProps } from "class-variance-authority";

import { MarketplaceLink } from "@components/MarketplaceLink";
import { simplifyAddress } from "@utils/index";
import { ImageWithFallback } from "./ImageWithFallback";

type Variants = {
  interaction: {
    none: string[];
    hover: string[];
  };
};

const avatar = cva<Variants>(
  [
    "box-content",
    "overflow-hidden",
    "rounded-full",
    "border-2",
    "border-white",
    "dark:border-jacarta-600",
  ],
  {
    variants: {
      interaction: {
        none: [""],
        hover: [
          "hover:cursor-pointer",
          "hover:border-accent",
          "dark:hover:border-accent",
        ],
      },
    },
    defaultVariants: {
      interaction: "hover",
    },
  }
);

const popup = cva<Variants>(
  [
    "invisible",
    "absolute",
    "right-[50%]",
    "bottom-8",
    "flex",
    "translate-x-[50%]",
    "items-center",
    "justify-center",
    "whitespace-nowrap",
    "rounded bg-jacarta-700",
    "border-jacarta-600 border-2",
    "p-2",
    "text-white",
  ],
  {
    variants: {
      interaction: {
        none: [],
        hover: ["md:peer-hover:visible"],
      },
    },
    defaultVariants: {
      interaction: "hover",
    },
  }
);

type AvatarProps = VariantProps<typeof avatar>;

export type UserProps = {
  user: User;
  type?: string;
  size: number;
} & AvatarProps;

export const UserAvatar: React.FC<UserProps> = ({
  user,
  type,
  size,
  interaction,
}) => {
  return (
    <MarketplaceLink
      href={`/user/${user.slug || user.address}`}
      className="relative transition-all duration-100 ease-in"
    >
      <div
        className={avatar({ interaction })}
        style={{ height: size, width: size }}
      >
        <div className="peer h-full w-full bg-jacarta-400">
          <ImageWithFallback
            entity={user}
            alt={type ?? ""}
            width={size}
            height={size}
          />
        </div>
        {type && (
          <div className={popup({ interaction })}>
            <span>{`${type}: ${
              user.name ?? simplifyAddress(user.address)
            }`}</span>
          </div>
        )}
      </div>
    </MarketplaceLink>
  );
};
