import { useContext } from "react";
import { ThemeContext, ThemeContextProps } from "../providers/theme";

export function useTheme(): ThemeContextProps {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within an ThemeProvider");
  }

  return context;
}
