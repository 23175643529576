import React from "react";
import Image from "next/image";
import { User } from "database";
import { defaultUserImage, isImageUrlInConfig } from "@utils/index";

type UserImageProps = {
  entity: Pick<User, "address" | "imageUrl">;
} & Omit<React.ComponentProps<typeof Image>, "src">;

export const ImageWithFallback = React.forwardRef<
  HTMLImageElement,
  UserImageProps
>((props, ref) => {
  const { entity, ...rest } = props;
  return (
    <Image
      {...rest}
      src={entity.imageUrl || defaultUserImage(entity)}
      alt={rest.alt}
      ref={ref}
      unoptimized={
        !!props.unoptimized ||
        (!!entity.imageUrl && !isImageUrlInConfig(entity.imageUrl))
      }
      onError={(e) => {
        console.error("Errored here", e);
        e.currentTarget.setAttribute("src", defaultUserImage(entity));
        rest.onError?.(e);
      }}
    />
  );
});
ImageWithFallback.displayName = "UserImage";
