import Image from "next/image";
import { Combobox } from "@headlessui/react";
import { useState, KeyboardEvent, useMemo } from "react";
import { useRouter } from "next/router";
import { useDebounce } from "react-use";

import { Subtitle } from "@components/LoadingModal";
import { UserAvatar } from "@components/UserAvatar";
import { MarketplaceLink } from "@components/MarketplaceLink";

import { simplifyAddress } from "@utils/index";
import { trpc } from "@utils/trpc";

import { ImageWithFallback } from "./ImageWithFallback";

export type SectionProps<T> = {
  title: string;
  items: Array<T>;
  render: (item: T) => React.ReactNode;
  extractor: (item: T) => {
    key: string;
    href: string;
  };
};

const divider = "divide-y-[1px] divide-gray-200 dark:divide-jacarta-500";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const SearchSection = <T extends unknown>({
  items,
  render,
  title,
  extractor,
}: SectionProps<T>) => {
  return (
    <div className={divider}>
      <div className="flex h-14 items-center p-3 text-sm font-semibold uppercase text-gray-400">
        {title}
      </div>
      <div className={divider}>
        {items.length > 0 ? (
          items.map((item) => {
            const { key, href } = extractor(item);
            return (
              <Combobox.Option key={key} value={item}>
                <MarketplaceLink
                  href={href}
                  className="group flex h-14 items-center space-x-2 px-3 py-1 text-md font-bold hover:bg-amber-600/10"
                >
                  {render(item)}
                </MarketplaceLink>
              </Combobox.Option>
            );
          })
        ) : (
          <div className="font-base flex h-14 items-center p-3 text-sm text-gray-400">
            No results
          </div>
        )}
      </div>
    </div>
  );
};

const itemText =
  "group-hover:text-accent dark:text-gray-100 dark:group-hover:text-accent-lighter text-sm";

export type SearchProps = {
  placeholder?: string;
};

export const SearchBar: React.FC<SearchProps> = ({
  placeholder = "Search by Collection, NFT or user",
}) => {
  const [inputText, setInputText] = useState<string>();
  const [searchText, setSearchText] = useState<string>(" 0x");
  const router = useRouter();
  const queryOptions = {
    enabled: !!searchText,
  };
  const { data: searchedUsers, isLoading: usersLoading } =
    trpc.user.getFind.useQuery({
      usersSearch: searchText,
      take: 4,
    });
  const { data: searchedCollections, isLoading: collectionsLoading } =
    trpc.collections.getFind.useQuery(
      {
        collectionsSearch: searchText,
        take: 4,
      },
      queryOptions
    );
  const { data: searchedArts, isLoading: artsLoading } =
    trpc.tokens.getFind.useQuery(
      {
        artsSearch: searchText,
        take: 4,
      },
      queryOptions
    );
  const [ready] = useDebounce(
    () => {
      const text = inputText ?? "";
      if (text.length >= 2) {
        setSearchText(text);
      }
    },
    500,
    [inputText]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      router.push({
        pathname: "/marketplace/search",
        ...(inputText?.trim() && {
          query: {
            query: inputText,
          },
        }),
      });
    }
  };
  const filteredArts = useMemo(
    () => (searchedArts || []).filter((art) => art.title !== "REMOVED_TOKEN"),
    [searchedArts]
  );
  const filteredUsers = useMemo(
    () => (searchedUsers || []).filter((user) => user.name !== "REMOVED_USER"),
    [searchedUsers]
  );
  const filteredCollections = useMemo(
    () =>
      (searchedCollections || []).filter(
        (collection) => collection.name !== "REMOVED_COLLECTION"
      ),
    [searchedCollections]
  );
  const loading = artsLoading || collectionsLoading || usersLoading || !ready();
  return (
    <Combobox>
      {({ open }) => (
        <div className="relative grow basis-3/12">
          <Combobox.Input
            placeholder={placeholder}
            className="w-full rounded-2xl border border-jacarta-100 py-[0.6875rem] px-4 pl-10 text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            onChange={(event) => {
              setInputText(event.target.value);
              setSearchText("");
            }}
            onKeyDown={handleKeyDown}
            // displayValue={(person) => person.name}
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="h-4 w-4 fill-jacarta-500 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
          {inputText && inputText.length >= 2 && open && (
            <div className="absolute top-full z-10 mt-3 w-full overflow-auto rounded-lg bg-white dark:bg-jacarta-600 sm:max-h-[calc(100vh-110px)]">
              {/*
                Using `static`, `Combobox.Options` are always rendered
                and the `open` state is ignored.
              */}
              {loading ? (
                <div className="flex h-14 items-center p-3 text-sm font-semibold uppercase text-gray-400">
                  Searching...
                </div>
              ) : (
                <Combobox.Options static className={divider}>
                  <SearchSection
                    title="Collections"
                    items={filteredCollections}
                    extractor={(col) => ({
                      key: col.address,
                      href: `/${col.address}`,
                    })}
                    render={(collection) => {
                      return (
                        <>
                          <div
                            className="overflow-hidden rounded-md"
                            style={{
                              height: 34,
                              width: 34,
                            }}
                          >
                            <ImageWithFallback
                              entity={collection}
                              height={34}
                              width={34}
                              className="aspect-square object-cover"
                              alt=""
                            />
                          </div>
                          <div>
                            <span className={itemText}>{collection.name}</span>
                            <Subtitle className="mt-0 text-xs dark:text-gray-300">
                              {collection._count.tokens} item
                              {collection._count.tokens > 1 && "s"}
                            </Subtitle>
                          </div>
                        </>
                      );
                    }}
                  />
                  <SearchSection
                    title="Items"
                    items={filteredArts}
                    extractor={(art) => ({
                      key: `${art.collectionAddress}/${art.id}`,
                      href: `/${art.collectionAddress}/${art.id}`,
                    })}
                    render={(art) => {
                      return (
                        <>
                          <div
                            className="overflow-hidden rounded-md"
                            style={{
                              height: 34,
                              width: 34,
                            }}
                          >
                            <Image
                              src={art.imageUrl}
                              height={34}
                              width={34}
                              className="aspect-square object-cover"
                              alt=""
                            />
                          </div>
                          <div>
                            <span className={itemText}>{art.title}</span>
                            <Subtitle className="mt-0 text-xs dark:text-gray-300">
                              {art.collection.name}
                            </Subtitle>
                          </div>
                        </>
                      );
                    }}
                  />
                  <SearchSection
                    title="Accounts"
                    items={filteredUsers}
                    extractor={(user) => ({
                      href: `/user/${user.address}`,
                      key: user.address,
                    })}
                    render={(user) => {
                      return (
                        <>
                          <UserAvatar
                            user={user}
                            size={36}
                            interaction="none"
                          />
                          {user.name ? (
                            <div>
                              <span className={itemText}>{user.name}</span>
                              <Subtitle className="mt-0 text-xs dark:text-gray-300">
                                {simplifyAddress(user.address)}
                              </Subtitle>
                            </div>
                          ) : (
                            <span className={itemText}>
                              {simplifyAddress(user.address)}
                            </span>
                          )}
                        </>
                      );
                    }}
                  />
                </Combobox.Options>
              )}
            </div>
          )}
        </div>
      )}
    </Combobox>
  );
};
