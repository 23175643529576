import { cva, VariantProps } from "class-variance-authority";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

const button = cva(
  ["inline-block", "rounded-full", "border-2", "text-center", "font-semibold"],
  {
    variants: {
      variant: {
        filled: "bg-accent text-white hover:bg-accent-dark border-accent",
        inverse:
          "text-accent border-accent hover:bg-gray-100 dark:hover:bg-gray-100/10",
        danger:
          "text-white dark:bg-red-800 bg-red-600 border-red-600 hover:bg-red-600/90  dark:hover:bg-red-800/80",
        disabled:
          "bg-jacarta-200 border-jacarta-200 text-white dark:bg-jacarta-500 cursor-not-allowed",
      },
      width: {
        fullWidth: "w-full",
        minWidth: "w-min whitespace-nowrap",
        grow: "grow",
      },
      padding: {
        normal: "py-3 px-8",
        small: "py-1 px-4",
      },
    },
    defaultVariants: {
      variant: "filled",
      width: "minWidth",
      padding: "normal",
    },
  }
);

type ButtonProps = VariantProps<typeof button> &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button: React.FC<React.PropsWithoutRef<ButtonProps>> = (props) => {
  const { variant, width, padding, className, ...rest } = props;

  return (
    <button
      {...rest}
      className={button({ variant, width, padding }) + ` ${className}`}
    />
  );
};
